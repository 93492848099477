<template>
  <b-container fluid class="bg-white-- vh-100 container-lg hw-100">
    <div class="element">
        <div class="text-center mb-3">  <img src="http://ezyagric.com/assets/svg/logo_full.svg" height="52px" /></div>

    <b-card class="bg-white border-0 shadow" style="border-radius: 8px !important;" id="lg-form">
      <b-card-body>
      
        <b-overlay :show="isBusy" spinner-variant="primary">
          <h5 class="text-center mb-4 font-weight-bold">Login to your account </h5>
          <b-form @submit.prevent="logIn">
            <b-input-group class="mt-3">
              <b-input-group-prepend is-text>
                <b-icon-person></b-icon-person>
              </b-input-group-prepend>
              <b-form-input
                type="text"
                v-model="username"
                placeholder="Username"
                required
              ></b-form-input>
            </b-input-group>
            <b-input-group class="mt-3 mb-4">
              <b-input-group-prepend is-text>
                <b-icon-lock></b-icon-lock>
              </b-input-group-prepend>
              <b-form-input
                type="password"
                v-model="password"
                placeholder="Password"
                required
              ></b-form-input>
            </b-input-group>
            <b-button variant="primary"  type="submit" class="w-100 rounded font-weight-bold">
              Log In
            </b-button>
            <b-alert v-model="error.exists" variant="danger" dismissible>
             {{error.msg}}
            </b-alert>
          </b-form>
          <p class="mb-0 mt-3 text-center">Don't have an account? <a class="text-primary" href="https://about.ezyagric.com/#contact-us">Contact Us</a></p>
        </b-overlay>
      </b-card-body>
    </b-card>
    <!-- <footer class="my-4 ">
          <a  href="https://ezyagric.com/" target="_blank">Agrishop</a>
          <a  href="https://ezyproduce.ezyagric.com/" target="_blank">EzyProduce</a>
          <a  href="https://about.ezyagric.com/" target="_blank">Official website</a>
       <a href="https://ezyagric.com/privacy-policy" target="_blank">Privacy Policy</a> 
    
    <span class="d-block mt-3">&copy;{{year}} Akorion Company ltd</span>
    </footer> -->

    <app-footer></app-footer>
    </div>
  </b-container>
</template>
<style lang="scss" >

@import '../assets/scss/breakpoints.scss';
@import '../assets/scss/variables';
body{
  background-color: #eff4f6;
}
.vh-100 {
  min-height: 100vh;
}
.hw-100 {
  min-width: 100vw;
}
.text-primary{
  color: $primary !important;
}


.element {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translateX(-50%) translateY(-50%);
  @include media ('<550px'){
    width: 90%;
  }
}
.container-lg {
  position: relative;
  margin: auto;
}
.input-group-text{
  border-right: none;
  background: none;
  border: none;
}
.input-group{
  background-color: #f1f1f7;
 
  border-radius: 8px;
  overflow: hidden;
    border: 1px solid transparent;

  &:focus-within{
    border: 1px solid $primary !important;
  }
.form-control{
  border: none !important;
  background: none !important;
  font-weight: 700;
  &:focus{
  box-shadow: none !important; ;
  
}
}}

</style>
<script>
import * as api from "./api";
import AppFooter from '../components/AppFooter.vue'
export default {
  components:{
    AppFooter
  },
  computed: {
    /**@returns {import("./utils").Token} */
    token() {
      return this.$store.getters["getToken"];
    },
  },
  data() {
   
    return {
      username: "",
      password: "",
      urlParams: {},
      isBusy: false,
      error:{
        exists:false,
        msg:""
      },
  
    };
  },
  mounted() {
    this.urlParams = this.$route.query;
    const clientId = this.$route.query["clientId"];
    if (this.token.isValid() && clientId) {
      this.getAuthCode(clientId);
    }
  },
  methods: {
    /** @param {{auth_code:string,access_token:string}} data*/
    authenticate(data) {
      const { auth_code, user_data: { apps } } = data;
      let { callback, clientId } = this.urlParams;
      if (!callback) {
        callback = (apps.find(app => app.id === clientId) || { callback: '' }).callback;
      }
      const myOtherUrl = new URL(callback);
      myOtherUrl.search = new URLSearchParams({ auth_code });
      window.location.href = myOtherUrl.toString();
    },
    getAuthCode(clientId) {
      this.isBusy = true;
        api.getAuthCode(this.token.toString(),clientId).then((data) => {
          this.isBusy = false;
          this.authenticate(data);
        });
    },
    logIn() {
      const username = this.username;
      const password = this.password;

      this.isBusy = true;
      api.login(username, password,this.urlParams).then((data) => {
        this.isBusy = false;
        const { user_data } = data;
        ///console.log(user_data)
        this.$store.dispatch("saveUsername", username);
        this.$store.dispatch("saveApps",user_data['apps']);


        const clientId = this.$route.query["clientId"];
        if (clientId) {
          this.authenticate(data);
          return 0;
        }
        this.$router.push("/")
      }).catch(reason=>{
        this.isBusy = false;
        this.error.exists = true;
        this.error.msg=reason;
  
      });
    },
  },
};
</script>