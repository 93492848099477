import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/LoginView.vue";
import DashboardView from "../views/DashboardView.vue";

import { checkAuthStatus, logOut } from "../views/api";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/",
    name: "dashboard",
    component: DashboardView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(async (to, _, next) => {
  const isLoggedIn = await checkAuthStatus();
  if (to.query.logout) {
    // eslint-disable-next-line no-unused-vars
    const { logout, ...rest } = to.query;
    await logOut();
    next({
      path: "/login",
      query: rest,
    });
  } else if (!isLoggedIn && to.name != "login") {
    next({
      path: "/login",
      query: to.query,
    });
  } else {
    next();
  }
});

export default router;
