import { isJwtExpired } from "jwt-check-expiration";
class Token {
    /**
     * @param {string} value
     */
    constructor(value) {
        this.value = value;
    }
    isExpired() {
        return isJwtExpired(this.value);
    }
    exists() {
        return this.value != null;
    }
    isValid() {
        if (this.exists()) {
            return !this.isExpired();
        }
        return false;
    }
    toString() {
        return this.value;
    }
    static fromStorage() {
        return new Token(localStorage.getItem("access_token"));
    }
    toStorage() {
        localStorage.setItem("access_token", this.value);
    }
}
export { Token }