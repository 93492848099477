import { Token } from '@/views/utils'
import Vue from 'vue'
import Vuex from 'vuex'
import * as api from "../views/api";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:Token.fromStorage(),
    username:null,
    apps:[],
  },
  getters: {
    getToken:state=>{
      return state.token;
    },
    getApps:state=>{
      return state.apps;
    }
  },
  mutations: {
    setToken:(state,access_token)=>{
      state.token = access_token;
    },
    saveUser(state,username){
      state.username=username;
    },
    setApps(state,apps){
      state.apps = apps;
    },
    signOut(state){
      state.apps = [];
      state.username = null;
      state.token = new Token(null);
      localStorage.clear();
    }
  },
  actions: {
    saveToken(context,access_token){
      const token = new Token(access_token);
      context.commit("setToken",token);
      token.toStorage();
    },
    saveUsername(context,username){
      context.commit("saveUser",username);
      localStorage.setItem("username",username);
    },
    /**
     * 
     * @param {*} context 
     * @param {import('@/views/api').App[]} apps 
     */
    saveApps(context,apps){
      context.commit("setApps",apps)
      localStorage.setItem("apps",JSON.stringify(apps));
    },
    loadUser(context){
      const username = localStorage.getItem("username");
      context.commit("saveUser",username)
    },
    loadToken(context){
      const token = Token.fromStorage();
      context.commit("setToken",token);
    },
    loadApps(context){
      const apps = localStorage.getItem("apps");
      if(!apps){
        return 0;
      }
      context.commit("setApps",JSON.parse(apps));
    },
    logOut(context){
      context.commit("signOut");
    },
    async setCrsfToken(){
      await api.getCsrfToken();
    }
  },
  modules: {
  }
})
