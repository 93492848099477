<template class="bg-white">
  <div class="">
    <b-navbar toggleable="lg" type="dark" class="sticky-top shadow-sm">
      <div class="container">

        <b-navbar-brand href="#" class="">
          EzyAgric Solutions
        </b-navbar-brand>
        <b-navbar-nav class="ml-auto">

          <button @click="logOut" class="btn btn-sm btn-outline-light">   <b-icon-power></b-icon-power> Log Out</button>
        </b-navbar-nav>
      </div>
    </b-navbar>


    <b-container style="min-height: 60vh">
      <h3 style="margin-top: 10%">Hello {{ username }},</h3>
      <div class="mt-4 row">
        <div class="col-12 col-md-6 my-2" v-for="app in apps" :key="app.id">
          <b-card class="shadow-sm bg-white m-0 " :title="app.name" >
            <b-card-text>{{ app.caption }}</b-card-text>
            <b-button href="#" :variant="app.owned | launch_variant" size="sm" :disabled="!app.owned"
              @click="launch(app.callback, app.id)">
              <b-spinner small label="Small Spinner" v-show="app.launching"></b-spinner>&nbsp;
              Launch <small>
                <b-icon-arrow-up-right></b-icon-arrow-up-right>
              </small>
            </b-button>
          </b-card>
        </div>
      </div>
    </b-container>
    <app-footer></app-footer>
  </div>
</template>
<script>
import * as api from "../views/api";
/**
 * @typedef {import("./api").App & {launching: boolean}} AppUi
 */
import AppFooter from '../components/AppFooter.vue'
export default {
  components:{
    AppFooter
  },
  computed: {
    /**
     * @returns {AppUi[]}
     */
    uapps() {
      let data = this.$store.getters["getApps"];
      return data;
    },

    username() {
      console.log(this.$store.state)
      return this.$store.state["username"];
    },
    /**
     * @return {import("./utils").Token}
     */
    token() {
      return this.$store.getters["getToken"];
    }
  },
  data() {
    return {
      /**
       * @type {AppUi[]}
       */
      apps: []
    }
  },
  filters: {
    launch_variant(has_access) {
      return has_access ? "primary" : "danger";
    },
  },
  mounted() {
    this.apps = this.uapps.map(el => {
      return { ...el, launching: false };
    });
  },
  methods: {
    async logOut() {
      await api.logOut().catch(err=>{
        console.log(err)
      })
      this.$store.dispatch("logOut").then(() => {
        this.$router.push("/login");
      })
    },
    launch(callback, clientId) {
      const appLoginStatus = JSON.parse(localStorage.getItem(`${clientId}_login_status`)||"false");
      if(appLoginStatus){
        this.handleLaunch(callback);
      }
      let client = this.apps.find(el => el["id"] == clientId);
      client["launching"] = true;
      api.getAuthCode(this.token.toString(), clientId).then((data) => {
        client["launching"] = false;
        const { auth_code } = data;
        localStorage.setItem(`${clientId}_login_status`, true);
        this.handleLaunch(callback, auth_code);
      }).catch(err => {
        client["launching"] = false;
        console.log(err);
      });
    },
    handleLaunch(callback, auth_code) {
        const myOtherUrl = new URL(callback);
        myOtherUrl.search = new URLSearchParams({ auth_code });
        if (this.$route.query.redirect) {
          window.location.href = myOtherUrl.toString();
          return
        }
        window.open(myOtherUrl.toString(), auth_code);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../assets/scss/breakpoints.scss';
@import '../assets/scss/variables';

nav {
  background-color: $primary;
  color: #fff;

  @include media('<550px') {
    .username {
      display: none;
    }
  }
}

.card-title {
  font-weight: bold;
}

.card {
  border: none;
}


.container {
  width: 60%;

  @include media('<850px') {
    width: 100%;

    h3 {
      font-size: 18px;
    }

  }

  @include media('<300px') {
    width: 300px;

    h3 {
      font-size: 15px;
    }
  }
}
</style>