import axios from "axios";

/**
 * @typedef App
 * @property {string} id
 * @property {string} name
 * @property {string} caption
 * @property {boolean} owner
 * @property {string} callback
 */

const BASE_URL = process.env.VUE_APP_BACKEND + "/auth";
const instance = axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
});

const requestHandler = async (request) => {
  if (request.url.includes("csrf_token")) {
    return request;
  }
  request.headers = {
    ...request.headers,
    "X-CSRFToken": request.headers["X-CSRFToken"] || (await getCsrfToken()),
    withCredentials: true,
    credentials: "include",
  };
  return request;
};

const errorHandler = (error) => {
  return Promise.reject(error);
};
instance.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);
/**
 * 
 * @param {string} username 
 * @param {string} password
 * @param {object} params

 */
async function login(username, password, params) {
  try {
    const res = await instance.post("/login", null, {
      auth: {
        username,
        password,
      },
      params,
      credentials: "include",
    });
    /** @type {{auth_code:string,user_data:{apps:App[]}}} */
    const data = res.data;
    return data;
  } catch (err) {
    if (err.response.status == 401) {
      throw "Invalid username/password";
    } else {
      throw "Unknown error";
    }
  }
}
/**
 *
 * @param {string} tkey
 * @param {string} clientId
 * @returns {Promise<{auth_code:string}>}
 */
async function getAuthCode(tkey, clientId) {
  const res = await instance.get("/auth_code", {
    params: { clientId },
  });
  return res.data;
}

/**
 * Get crsf token
 */
async function getCsrfToken() {
  try {
    const res = await instance.get("/csrf_token");
    const csrfToken = res.headers["x-csrftoken"];
    return csrfToken;
  } catch (error) {
    return "";
  }
}

async function checkAuthStatus() {
  try {
    const res = await instance.get("/session");
    return res.data.login;
  } catch (error) {
    return false;
  }
}

async function logOut() {
  try {
    const res = await instance.get("/logout");
    return res.data.login;
  } catch (error) {
    return false;
  }
}
export { login, getAuthCode, getCsrfToken, checkAuthStatus, logOut };
