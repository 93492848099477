<template>
 <div class="footer my-4 ">
          <a  href="https://ezyagric.com/" target="_blank">Agrishop</a>
          <a  href="https://ezyproduce.ezyagric.com/" target="_blank">EzyProduce</a>
          <a  href="https://about.ezyagric.com/" target="_blank">Official website</a>
       <a href="https://ezyagric.com/privacy-policy" target="_blank">Privacy Policy</a> 
    
    <span class="d-block mt-3">&copy;{{year}} Akorion Company ltd</span>
    </div>
</template>
<script>
export default{

 data() {
    const year =    new Date().getFullYear();
   return {
      year
   }
 }
 }
</script>
<style lang="scss">

.footer{
  font-size: 13px;
  text-align: center;
  a{
    display: inline-block;
    color: #999;
    font-weight: bold;
    // border-left: 1px solid  #999;;
    padding: 0 8px;
  }
 
}
</style>