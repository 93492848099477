<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
@import './assets/scss/variables';
#app {
  font-family:  'Nunito Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  
}
.btn-primary{
  background-color: $primary !important;
  border-color: $primary !important;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.bg-white-lime {
  background-color: #f0f0f0;
}
</style>
<script>
export default {
  created(){
    this.$store.dispatch("loadUser");
    this.$store.dispatch("loadToken");
    this.$store.dispatch("loadApps");
  }
}
</script>
